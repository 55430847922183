<template>
  <div class="main-content">
    <h3 class="marginBottom20">基础设置</h3>
    <avue-form ref="form"
               v-model="form"
               :option="formOption"
               :upload-error="uploadError"
               :upload-delete="uploadDelete"
               :upload-after="uploadAfter"
               :upload-before="uploadBefore"
               @submit="submit">
      <template slot="h3One">
        <h3>推荐朋友</h3>
      </template>

      <template slot="h3Two">
        <h3>蜜豆设置</h3>
      </template>

      <template slot="money">
        <div class="flex align-items">
          <span class="marginRight20">1</span>
          <span class="marginRight20">:</span>
          <el-input v-model="form.money"
                    placeholder="现金"
                    class="width300"
                    @blur="changeBlur($event,'money',10000, 0.01)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
          </el-input>
        </div>
        <div style="color: #f59a23">（例如：蜜豆：现金金额=1:1）</div>
      </template>

      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleBack">取消</el-button>
      </template>
    </avue-form>
  </div>
</template>

<script>
import {update, view} from "@/api/honey/setting";

export default {
  name: "index",
  data(){
    return{
      form: {
        shareCopy: '',
        sharePost: ''
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 180,
        column: [
          {
            label: '',
            prop: 'h3One',
            span: 24,
            row: true,
            labelWidth: 50
          },
          {
            label: '分享文案',
            prop: 'shareCopy',
            type: 'textarea',
            span: 12,
            maxRows: 3,
            row: true,
            maxlength: 100,
            showWordLimit: true,
            rules: [{
              required: true,
              message: "请输入分享文案",
              trigger: "blur"
            }]
          },
          {
            label: '分享海报',
            prop: 'sharePost',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '建议尺寸：590*590,最大20M',
            fileSize: 20 * 1024,
            span: 24,
            rules: [{
              required: true,
              message: "请上传分享海报",
              trigger: "blur"
            }]
          },
          {
            label: '',
            prop: 'h3Two',
            span: 24,
            row: true,
            labelWidth: 50
          },
          {
            label: '蜜豆与现金比例设置',
            prop: 'money',
            span: 24,
            row: true,
            rules: [{
              required: true,
              message: "请输入蜜豆与现金比例设置",
              trigger: "blur"
            }]
          }
        ]
      }
    }
  },
  mounted() {
    this.getView();
  },
  methods: {
    getView(){
      view().then(res=>{
        // console.log(res)
        if(res.code === 200){
          this.form = res.data;
        }else{
          this.$message.error(res.message);
        }
      })
    },
    submit(form,done){
      // console.log(form)
      update(form).then(async res => {
        if (res.code === 200) {
          this.$message.success('设置成功');
          await this.getView();
          done();
        } else {
          this.$message.error(res.message);
          done();
        }
      }).catch(()=> done())
    },
    handleBack(){
      this.$router.go(-1);
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        column.propsHttp.name = res.path;
      }
    },
    // 限制输入框
    changeBlur(e,text,max, min){
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          // console.log(Number(value))
          if(Number(value) > Number(max)){
            this.form[text] = Number(max)
          }else if(Number(value) < Number(min)){
            this.form[text] = Number(min)
          }else{
            this.form[text] = Number(value)
          }
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
  }
}
</script>

<style scoped>

</style>